var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5dcb9527a71df9868b5792b7f4fd46eb1c3d7148"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/app";

/* eslint-disable filenames/match-regex */
import * as Sentry from '@sentry/nextjs'

import { defaultSentryClientConfig } from '@twisto/nextjs/sentry-config'

Sentry.init({
  ...defaultSentryClientConfig,
  dsn: 'https://3b9d269c1585b430d2a24e8dc71d71be@o430641.ingest.sentry.io/4506588590178304',
  replaysOnErrorSampleRate: 0.01,
  integrations: [Sentry.replayIntegration()],
})

import Head from 'next/head'
import { DefaultSeo } from 'next-seo'

import { defaultSeoCopy } from './default-seo-copy'

const head = {
  CZ: {
    domain: 'https://www.twisto.cz',
  },
  PL: {
    domain: 'https://www.twisto.pl',
  },
}

type Props = {
  region: GLOBALS.Region
  lang: GLOBALS.Language
}
/** tags common to all pages (doesn't include title) */
export const MetaTags = ({ region, lang }: Props) => (
  <>
    <DefaultSeo
      description={defaultSeoCopy[lang].description}
      openGraph={{
        url: head[region].domain,
      }}
      title={defaultSeoCopy[lang].title}
      titleTemplate={`%s – ${defaultSeoCopy[lang].shortTitle}`}
    />
    <Head>
      <meta content="width=device-width,initial-scale=1.0" name="viewport" />
      <meta
        content={defaultSeoCopy[lang].shortTitle}
        name="apple-mobile-web-app-title"
      />
      <meta content={defaultSeoCopy[lang].shortTitle} name="application-name" />
      <meta content="yes" name="mobile-web-app-capable" />
      <meta content="yes" name="apple-mobile-web-app-capable" />
      <link href="https://www.googletagmanager.com" rel="preconnect" />
      {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (region === 'CZ' || region === 'PL') && (
          <>
            <meta content="app-id=1182925330" name="apple-itunes-app" />
            <meta
              content="https://static.twistopay.com/base/favicons/browserconfig.xml"
              name="msapplication-config"
            />
          </>
        )
      }
    </Head>
  </>
)

import { css } from '@emotion/react'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'

import { Browser } from '@twisto/components/atoms/browser'
import { HeadTags } from '@twisto/components/organisms/head-tags'
import { parseLocale } from '@twisto/nextjs/locale'

const main = css`
  overflow-x: hidden;
`

type AppPropsWithLayout = AppProps & {
  Component: NextPage
}

const MyApp = ({ Component, pageProps, router }: AppPropsWithLayout) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { region, lang } = parseLocale(router.locale as any)

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? (({ children }) => children)

  return (
    <>
      <Head>
        {/* the manifest is hosted at root */}
        <link href="/manifest.json" rel="manifest" />
      </Head>
      <HeadTags lang={lang} region={region} />
      <Browser>
        <div css={main}>
          {getLayout({ children: <Component {...pageProps} /> })}
        </div>
      </Browser>
    </>
  )
}

export default MyApp

/// <reference types="next/image-types/global" />
import Head from 'next/head'

import twistoAppleTouchIcon from './assets/favicons/apple-touch-icon.png'
import twistoFavicon16 from './assets/favicons/favicon-16x16.png'
import twistoFavicon32 from './assets/favicons/favicon-32x32.png'
import twistoFavicon from './assets/favicons/favicon.ico'
import twistoSafariPinnedTab from './assets/favicons/safari-pinned-tab.svg'

export const Favicons = () => (
  <Head>
    <link
      href={twistoFavicon32.src}
      rel="icon"
      sizes="32x32"
      type="image/png"
    />
    <link
      href={twistoFavicon16.src}
      rel="icon"
      sizes="16x16"
      type="image/png"
    />
    <link color="#4f198c" href={twistoSafariPinnedTab.src} rel="mask-icon" />
    <link href={twistoFavicon.src} rel="icon" />
    <link
      href={twistoAppleTouchIcon.src}
      rel="apple-touch-icon"
      sizes="180x180"
    />
    <meta content="#ffffff" name="theme-color" />
    <meta content="#ffffff" name="apple-mobile-web-app-status-bar-style" />
    <meta content="#ffffff" name="msapplication-navbutton-color" />
  </Head>
)

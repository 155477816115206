export const defaultSeoCopy = {
  cs: {
    title:
      'Nakupuj přes Twisto a plať později. S Twisto appkou máš finance pod palcem',
    shortTitle: 'Twisto',
    description:
      'Plať po svém. Nakupuj a plať do 45 dnů nebo později. Díky Twisto appce máš všechny svoje platby, splátky a jiné služby pohromadě.',
  },
  pl: {
    title: 'Życie nie zaczeka, Twisto tak',
    shortTitle: 'Twisto',
    description:
      'Robisz zakupy i decydujesz, kiedy chcesz zapłacić - od razu lub nawet do 45 dni! Bez opłat i prowizji.',
  },
  en: {
    title: 'Buy now, pay later',
    shortTitle: 'Twisto',
    description:
      'New payment method that let’s you check the goods before you pay - same as in a retail shop. Buy, check the goods and pay if everything is okay - up untill 30 days for free.',
  },
}

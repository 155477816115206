/** @typedef {`${GLOBALS.Language}-${GLOBALS.Region}`} Locale */

/** @type {Record<GLOBALS.Region, Locale>} */
const regionMap = {
  CZ: 'cs-CZ',
  PL: 'pl-PL',
}
exports.regionMap = regionMap

const defaultLocale = 'en-CZ'
exports.defaultLocale = defaultLocale

/**
 *
 * @param {Locale | typeof defaultLocale | undefined | null} locale
 * @returns {{ region: GLOBALS.Region; lang: GLOBALS.Language }}
 */
const parseLocale = (locale) => {
  if (locale === undefined || locale === null || locale === defaultLocale) {
    return { region: 'CZ', lang: 'en' }
  }

  if (!locale.includes('-')) {
    throw new Error('Invalid locale')
  }

  /** @type GLOBALS.Language */
  const lang = locale.split('-')[0] ?? 'en'
  /** @type GLOBALS.Region */
  const region = locale.split('-')[1] ?? 'CZ'

  return {
    region,
    lang,
  }
}
exports.parseLocale = parseLocale

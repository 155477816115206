/** localhost with backend */
exports.development = {
  APP_PORT: 3000,
  MOCK_PORT: 4000,
  // Public API key for FingerprintJSPro can be found in the project settings
  // https://dashboard.fingerprint.com/subscriptions/sub_sFjEgZ5leGrBPl/tokens
  FINGERPRINT_PRO_JS_PUBLIC_KEY: 'dskXZE9mg0eiVmOT8QDD',
  TWISTO_JS_PROD_PUBLIC_KEY_V1:
    'live_pk_wseqsh04hgwp5cyctp8u9rpjrosdzotq5ye7yzm8bs6at77ccc',
  TWISTO_JS_TEST_PUBLIC_KEY_V1:
    'test_pk_ejzfhlu0bx92yejaa6ptzngxxoez4n48ogagxwlsy9w9wckrxt',
  TWISTO_JS_PROD_PUBLIC_KEY_V2:
    'live_pk_aeyxhytv7coiu8nxz7jgkn42cie72ybk6jt4oybsn853anaw6p',
  TWISTO_JS_TEST_PUBLIC_KEY_V2:
    'test_pk_yn4bvm13hn5vutnczxr56w2a4t5uwi2ixvrv0kfj9ovart4757',
  MARQETA_CARD_WIDGET_JS: 'http://localhost:4000/static/marqeta.js',
  OCEAN_CARD_WIDGET_JS:
    'https://cloud-ocean-test-widget.paycore.com/v1/assets/ocean-external-client-widget.min.js',
  DAKTELA_GUI_TOKEN_CZ: 'o4psq2751r594r0366115834pq2s6r4q',
  DAKTELA_GUI_TOKEN_PL: 'po06305878987554219q2p824q505902',
  // OCEAN_CARD_WIDGET_JS:
  //   'https://cloud-ocean-test-widget.paycore.com/v1/assets/ocean-external-client-widget.min.js ',
}

//   OCEAN_CARD_WIDGET_JS:
//     'http://k8s-testocea-testocea-79f43c1bb0-543015368.eu-central-1.elb.amazonaws.com/v1/assets/ocean-external-client-widget.min.js',

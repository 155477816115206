import { useAtom } from 'jotai'
import { isNil } from 'ramda'

import { remoteLanguageAtom } from '@twisto/base'
import { isBrowser } from '@twisto/environment'

import { Favicons } from './favicons'
import { GtmInit } from './gtm-init'
import { MetaTags } from './meta-tags'

type Props = {
  region: GLOBALS.Region
  lang: GLOBALS.Language
  provideGtm?: boolean
}

export const HeadTags = ({
  region,
  lang: defaultLang,
  provideGtm = true,
}: Props) => {
  const [storedRemoteLanguage] = useAtom(remoteLanguageAtom)
  const storedLang =
    isBrowser &&
    storedRemoteLanguage &&
    !isNil(storedRemoteLanguage.userLanguage)
      ? storedRemoteLanguage.userLanguage.language ??
        storedRemoteLanguage.userLanguage.default
      : undefined

  const lang = storedLang ?? defaultLang

  const globals = `
    window.REGION = "${region}"
    window.LANG = "${lang}"
    window.BASEPATH = "${process.env.BASEPATH}"
  `

  return (
    <>
      <MetaTags lang={lang} region={region} />
      <Favicons />
      {provideGtm && <GtmInit region={region} />}
      <script
        dangerouslySetInnerHTML={{ __html: globals }}
        suppressHydrationWarning
      />
    </>
  )
}

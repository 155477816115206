const { development } = require('./development')
const { production } = require('./production')
const { stage } = require('./stage')
const { staging } = require('./staging')
const { testserver } = require('./testserver')

/** @type {typeof development | typeof staging | typeof production | typeof testserver | typeof stage } */
let currentSettings = development

const isTestServer =
  typeof window !== 'undefined' &&
  /twisto\.(wtf|top)/.exec(window.location.hostname) !== null

switch (process.env.WEB_ENV) {
  case 'production':
    /*
      On testserver is by default used master build with production settings.
      NextJS is not able to set WEB_ENV dynamically, so we need to check hostname.
      It replace all process.env variables with real values during the build.
    */

    if (isTestServer) {
      currentSettings = staging
      break
    }
    currentSettings = production
    break
  case 'staging':
    currentSettings = staging
    break
  case 'stage':
    currentSettings = stage
    break
  case 'testserver':
    currentSettings = testserver
    break
  case 'development':
  default:
    currentSettings = development
}

exports.settings = currentSettings
exports.productionSettings = production
exports.stagingSettings = staging
exports.testserverSettings = testserver
exports.developmentSettings = development
exports.stageSettings = stage

import { atomWithLocalStorage } from '@twisto/utils'

export type Language =
  /** Czech (česky) */
  | 'cs'
  /** English (English) */
  | 'en'
  /** Polish (polski) */
  | 'pl'

export type RemoteLanguage = {
  userLanguage: {
    default: Language
    language: Language | null
    options: Language[]
  }
}

export const remoteLanguageAtom = atomWithLocalStorage<
  RemoteLanguage | undefined
>('remoteLanguage', undefined)

import { atom } from 'jotai'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import { equals } from 'ramda'

export function deepEqualAtom<Value>(initialValue: Value) {
  const state = atom<Value>(initialValue)

  return atom<Value, Value>(
    (get) => get(state),
    (get, set, newValue: Value) => {
      if (!equals(get(state), newValue)) {
        set(state, newValue)
      }
    }
  )
}

export const atomWithLocalStorage = <Value>(
  key: string,
  initialValue: Value
) => {
  // user can disable localStorage in browser settings
  try {
    // eslint-disable-next-line no-unused-expressions
    window.localStorage

    return atomWithStorage<Value>(key, initialValue, {
      ...createJSONStorage(() => localStorage),
      delayInit: false,
    })
  } catch {
    return atom<Value>(initialValue)
  }
}

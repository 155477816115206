// @ts-check
const IS_TEST = process.env.NODE_ENV === 'test'
const IS_DEV = process.env.NODE_ENV === 'development'
const IS_PROD = process.env.NODE_ENV === 'production'
const IS_CI = !!process.env.CI

/**
 * WEB_ENV indicates runtime environment
 * production - selfexplanatory
 * staging - staging environment
 * testserver - selfexplanatory
 * development - localhost docker setup
 */
const IS_DEV_RUNTIME =
  process.env.WEB_ENV === 'development' || process.env.WEB_ENV === undefined
const IS_PROD_RUNTIME = process.env.WEB_ENV === 'production'

/**
 * moved from libs/base/environment.ts
 * TODO: do unification and cleanup of these and above defined variables
 */
const isProd = process.env.ENV === 'production'
const isTest = process.env.NODE_ENV === 'test'
const isDev =
  process.env.ENV === 'development' || process.env.ENV === 'integration'
const isTestServer =
  process.browser && window.location.origin.includes('.ts.twisto.wtf')
const isBrowser = !!process.browser
const gitRevision = process.env.GIT_REVISION
const HEALTHCHECK_PATH = '/api/healthcheck/'

/** settings reexport */

const {
  settings,
  productionSettings,
  stagingSettings,
  testserverSettings,
  developmentSettings,
  stageSettings,
} = require('./settings')

module.exports = {
  settings,
  productionSettings,
  testserverSettings,
  stagingSettings,
  stageSettings,
  developmentSettings,
  IS_TEST,
  IS_DEV,
  IS_PROD,
  IS_CI,
  IS_DEV_RUNTIME,
  IS_PROD_RUNTIME,
  isProd,
  isTest,
  isDev,
  isTestServer,
  isBrowser,
  gitRevision,
  HEALTHCHECK_PATH,
}

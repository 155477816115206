/** production environment */
exports.production = {
  // Public API key for FingerprintJSPro can be found in the project settings
  // https://dashboard.fingerprint.com/subscriptions/sub_sFjEgZ5leGrBPl/tokens
  FINGERPRINT_PRO_JS_PUBLIC_KEY: 'HpoSqoLonyWskSUnEusH',
  TWISTO_JS_PROD_PUBLIC_KEY_V1:
    'live_pk_q8i5eypcrxmv0dfsx14mh6vfpxkn7clksgr67hrgoljqw03gt2',
  TWISTO_JS_TEST_PUBLIC_KEY_V1:
    'test_pk_rr2dxj5wzi5i4fthhe0o7zqctb4ovc9m87kiuobbb6jxvnh5bo',
  TWISTO_JS_PROD_PUBLIC_KEY_V2:
    'live_pk_aeyxhytv7coiu8nxz7jgkn42cie72ybk6jt4oybsn853anaw6p',
  TWISTO_JS_TEST_PUBLIC_KEY_V2:
    'test_pk_yn4bvm13hn5vutnczxr56w2a4t5uwi2ixvrv0kfj9ovart4757',
  MARQETA_CARD_WIDGET_JS:
    'https://widgets.marqeta.com/marqetajs/2.0.0/marqeta.min.js',
  OCEAN_CARD_WIDGET_JS:
    'https://cloud-ocean-widget.paycore.com/v1/assets/ocean-external-client-widget.min.js',
  DAKTELA_GUI_TOKEN_CZ: 'pr2o4p851469orr6o7rq74r0o26946s9',
  DAKTELA_GUI_TOKEN_PL: '9s25n52n0prn82nrr96q1ppssor1r88n',
}

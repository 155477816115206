const { IS_DEV, isTestServer } = require('@twisto/environment')

/** Sentry Webpack Plugin Config */
/** @type {Partial<import('@sentry/nextjs/types/index.server').SentryWebpackPluginOptions>} */
const sentryPluginConfig = {
  silent: true,
  release: process.env.GIT_COMMIT,
  authToken: process.env.SENTRY_AUTH_TOKEN,
  tracesSampleRate: 0,
  url: 'https://sentry.io/',
  org: 'twisto',
}
exports.defaultSentryPluginConfig = sentryPluginConfig

const DEFAULT_CLIENT_SAMPLE_RATE = 0.02

/** @type {import('@sentry/react').BrowserOptions} */
const defaultSentryClientConfig = {
  environment: isTestServer
    ? 'testserver'
    : process.env.WEB_ENV ?? 'development',
  tracesSampleRate: DEFAULT_CLIENT_SAMPLE_RATE,
  tracesSampler: (samplingContext) => {
    const { transactionContext } = samplingContext
    if (transactionContext.op === 'middleware.nextjs') {
      return 0
    }

    return DEFAULT_CLIENT_SAMPLE_RATE
  },
  enabled: !IS_DEV,
  beforeSend: (event) => {
    const { request } = event
    // discard events from jsdom user agent (crawlers, not real users)
    if (request?.headers?.['User-Agent'].includes('jsdom') === true) {
      return null
    }

    return event
  },
  ignoreErrors: [
    // https://github.com/ZeeCoder/use-resize-observer/issues/38
    'ResizeObserver loop completed with undelivered notifications.',
    // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/25
    'Object Not Found Matching Id:',
    // https://stackoverflow.com/a/60860369
    'Failed to fetch',
    'Load failed',
    'NetworkError when attempting to fetch resource.',
    'The operation was aborted',
    'Fetch is aborted',
    'The user aborted a request',
    /** recommended settings from Sentry docs below */
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    "Can't find variable: dat",
    'dat is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
  ],
  denyUrls: [
    /** recommended settings from Sentry docs below */
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    // disable GTM/Google analytics scripts and Clarity scripts errors from our Sentry
    /\/(ga|analytics|clarity)\.js/i,
  ],
}
exports.defaultSentryClientConfig = defaultSentryClientConfig

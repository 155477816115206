import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'

type Props = {
  children: ReactNode
}
export const Browser = ({ children }: Props) => {
  const [isClient, setIsClient] = useState(false)
  // this makes sure that initial render on client doesn't mismatch SSR result
  // only once effect is evaluated client side react hooks up
  useEffect(() => setIsClient(true), [])

  return <>{isClient ? children : null}</>
}

import { isDev } from '@twisto/environment'

const gtmIdMap = {
  CZ: 'GTM-TJMPN9',
  PL: 'GTM-WHHJCHS',
}

type Props = {
  region: GLOBALS.Region
}
export const GtmInit = ({ region }: Props) => {
  const dataLayer = [
    `window.dataLayer=window.dataLayer||[];`,
    `var gtag=gtag || function(){window.dataLayer.push(arguments)};`,
    `gtag('js',new Date());`,
    `var ccCookie=document.cookie.split('; ').find(row => row.startsWith('cc_cookie='));`,
    `var consentsArray=ccCookie?JSON.parse(ccCookie.split('=')[1]).level:[];`,
    `gtag('consent','default',{`,
    `'ad_storage': consentsArray.includes('ads')?'granted':'denied',`,
    `'analytics_storage': consentsArray.includes('analytics')?'granted':'denied'`,
    `});`,
    `window.GTM={}`,
  ].join('')
  const gtmContainer = [
    `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':`,
    `new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],`,
    `j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=`,
    `'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);`,
    `})(window,document,'script','dataLayer','${gtmIdMap[region]}');`,
  ].join('')

  return (
    <>
      <script dangerouslySetInnerHTML={{ __html: dataLayer }} />
      {!isDev && <script dangerouslySetInnerHTML={{ __html: gtmContainer }} />}
    </>
  )
}
